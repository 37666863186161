<template>
  <div class="page-container">
    <LoadingIcon type="dark" />
  </div>
</template>

<script>

  import LoadingIcon from '@/components/utils/LoadingIcon.vue'

  export default {
    components: {
      LoadingIcon,
    },
    created() {
      this.$store.dispatch('app/LOGOUT')
    },
  }

</script>

<style lang="stylus" scoped>

  .page-container
    @apply w-full
    @apply h-full

    @apply flex
    @apply items-center
    @apply justify-center

</style>
